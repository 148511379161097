.App {
  text-align: center;
}

.dead-pointer {
  pointer-events: none;
}

.coming-soon {
  height: 200px;
  position: fixed;
  top: 0;
  z-index: 99;
}
.logo1 {
  height: 30vmin;
  max-height: 300px;
  min-height: 200px;
  min-width: 200px;
  z-index: 95;
}
.gear1 {
  height: 30vmin;
  max-height: 275px;
  min-height: 165px;
  min-width: 165px;
  width: 30vmin;
  z-index: 90;
}
.hand1 {
  height: 65vmin;
  max-height: 300px;
  z-index: 85;
}
.comp1 {
  height: 60vmin;
  max-height: 450px;
  min-height: 200px;
  min-width: 200px;
  width: 60vmin;
  z-index: 80;
}
.hand2 {
  height: 60vmin;
  max-height: 600px;
  z-index: 75;
}
.hand3 {
  height: 55vmin;
  max-height: 500px;
  z-index: 70;
}
.gear2 {
  height: 70vmin;
  max-height: 480px;
  z-index: 65;
}
.gear3 {
  height: 80vmin;
  max-height: 550px;
  opacity: .15;
  z-index: 60;
}

@media (prefers-reduced-motion: no-preference) {
  .spin-0 {
    animation: animation-spin-0 infinite 5s linear;
  }
  .spin-1 {
    animation: animation-spin-1 infinite 22s linear;
  }
  .spin-2 {
    animation: animation-spin-2 infinite 47s linear;
  }
  .spin-3 {
    animation: animation-spin-3 infinite 79s linear;
  }
  .spin-4 {
    animation: animation-spin-4 infinite 163s linear;
  }
  .spin-5 {
    animation: animation-spin-5 infinite 501s linear;
  }
}

.App-header {
  align-items: center;
  background-color: #282c34;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url(./images/maps/Drenwal-World-Map-v7_3-frameless.jpg) no-repeat;
    background-position: center center;
    background-size: cover;
  color: white;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  justify-content: center;
  min-height: 100vh;
  position: relative;
}

.App-link {
  color: #61dafb;
}

.stay-boy {
  position: absolute;
}

@keyframes animation-spin-0 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes animation-spin-1 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes animation-spin-2 {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}
@keyframes animation-spin-3 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes animation-spin-4 {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}
@keyframes animation-spin-5 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}